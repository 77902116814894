.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes transitionIn {
  from {
        opacity: 0;
        transform: rotateX(-10deg);
  }

  to {
        opacity: 1;
        transform: rotateX(0);
  }
  
}

.container {
  animation:transitionIn 0.75s;
}


@font-face {
  font-family: 'BankGothicMDBT';
  src: url('./fonts/BankGothicMDBT.ttf') format('truetype');
}

@font-face {
  font-family: 'times';
  src: url('./fonts/times.ttf') format('truetype');
}




.marquee-tag-container {
  display: flex;
  align-items: center;
}

.marquee-tag-icon {
  padding: 10px;
  background-color: aqua;
  border-radius: 9999px;
}

.marquee-tag {
  margin-left: 0.75rem;
}

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 200px;
  overflow-x: hidden;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 32s linear infinite;
}

@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}

.white-text-shadow {
  text-shadow: #FFFF 1px 0 7px;
}
